<template>
  <div class="input_mypwd">
    <el-input
      v-model="input"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :type="passShow ? '' : 'password'"
      @input="inputChange"
    >
      <img
        slot="suffix"
        class="icon_pwd"
        style="width: 20px"
        src="@/assets/image/login/common/pwd.png"
        alt=""
        v-if="passShow"
        @click="passShow = !passShow"
      />
      <img
        slot="suffix"
        class="icon_pwd"
        style="width: 20px"
        src="@/assets/image/login/common/pwdno.png"
        alt=""
        @click="passShow = !passShow"
        v-if="!passShow"
      />
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: () => {
        return "请输入密码";
      },
    },
    maxlength: {
      type: [String, Number],
      default: () => {
        return 10;
      },
    },
    value: {
      type: [String, Number],
      default() {
        return "";
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        this.input = val;
      },
      deep: true,
    },
  },
  data() {
    return {
      input: this.value,
      passShow: false,
    };
  },
  methods: {
    inputChange(value) {
      this.input = value;
      this.$emit("input", this.input);
    },
  },
};
</script>

<style lang="scss" >
.input_mypwd {
  width: 100%;
  .el-input__suffix {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 9px;
  }
  .icon_pwd {
    cursor: pointer;
    width: 20px;
  }
}
</style>