import Vue from 'vue'
import VueRouter from 'vue-router'
import * as http from '@/api/request'
import api from "@/api/api"
import cookies from "vue-cookies";
Vue.use(VueRouter)

const routes = [
  { path: '', redirect: '/LoginEntrance', hidden: true },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: { title: '登录' },
    hidden: true,
  },
  {
    path: '/LoginEntrance',
    name: 'LoginEntrance',
    component: () => import('@/views/login/entrance.vue'),
    meta: { title: '登录入口' },
    hidden: true,
  },
  {
    path: '/PermissionDenied',
    name: 'PermissionDenied',
    component: () => import('@/views/login/PermissionDenied.vue'),
    meta: { title: '权限不足' },
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error/401.vue'),
    hidden: true,
  },
]

const router = new VueRouter({
  mode: 'hash',
  base:'/hnyfkj-sso/',
  routes,
})
function parseQueryString(url) {
  // 匹配以 ? 开头的参数部分，并提取其后的键值对
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  let params = {};
  let match;
  while ((match = regex.exec(url)) !== null) {
    // 解码参数值，避免特殊字符造成的问题
    let key = decodeURIComponent(match[1]);
    let value = decodeURIComponent(match[2]);
    // 如果参数重复出现，则存储为数组
    if (params[key] !== undefined) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]];
      }
      params[key].push(value);
    } else {
      params[key] = value;
    }
  }
  return params;
}
// // 路由守卫设置
router.beforeEach((to, from, next) => {
  console.log(to,'to')
  console.log(from,'from')
  let toPath = to.path
  // 判断登陆信息不存在并且进入不是登录页，跳转到登录页面

  if (toPath == '/login') {
    return next()
  }
  if (toPath == '/PermissionDenied') {
    return next()
  }
  if (toPath == '/401') {
    return next()
  }
  // next()
  // let token = localStorage.getItem('satoken')
  let token = cookies.get('satoken')
  if (token && token != ''&&toPath!='/login') {
    // 判断用户是否登陆
    http.getAction(api.ssoisLogin).then(({ data: res }) => {
      if (res.code == 200) {//token未失效
        // let parseQuery = parseQueryString(window.location.href);
        // if (Object.keys(parseQuery).length) {
        //   if (parseQuery.redirect) {
        //     console.log("存在redirect字段，其值为：");
        //     let url =
        //       parseQuery.redirect + "?ticket=" + localStorage.getItem('ticket');
        //     window.location.href = url;
        //     return
        //   } else {
        //     console.log("不存在redirect字段");
        //     next()
        //   }
        // } else {
        //   console.log("没有查询参数");
        //   next()
        // }
        next()

      } else {
        return next('/login')
      }
    }).catch(err=>{
      return next('/login')
    })
    next()
  } else {
    // 重定向至认证中心
    return next('/login')
  }
})


export default router
