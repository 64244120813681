import axios from 'axios'
axios.defaults.withCredentials = true
import api from './api'
import { Loading } from 'element-ui'
import { Message } from 'element-ui'
import modal from '@/utils/modal'
// import api from './apiMock'
// 请求地址
// let baseUrl = '/user-web' // 本地调试
let baseUrl = '/hnyfkj-sso' // 本地调试
// let baseUrl = 'http://119.91.150.78:9300/hnyfkj-sso' // 本地调试
// let baseUrl = 'http://192.168.100.29:8082/hnyfkj-sso' // 本地调试
let axiosInstance = axios.create({})
let token = ''
let loadingInstance = null
// 请求拦截
axiosInstance.interceptors.request.use(
	(config) => {
		// loadingInstance = Loading.service({ fullscreen: true })
		// console.log(config)
		// console.log(config.data.organId)
		// if (config.data.organId != undefined) {
		// 	if (config.data.organId == null || config.data.organId == '') {
		// 		Message('organId为空,请重新登录', 'error')
		// 		window.location.href = '#/login'
		// 		return
		// 	}
		// }
		token = localStorage.getItem('satoken')
		// 全局设置头部token
		config.headers['satoken'] = token
		return config
	},
	(err) => {
		return Promise.reject(err)
	}
)
// 响应拦截器
axiosInstance.interceptors.response.use(
	(reponse) => {
		// loadingInstance.close()
		if (reponse.data.code == 401) {
			// console.log('token失效，请重新登录')
			window.localStorage.clear()
			// window.location.href = '#/401'
			window.location.href = '#/login'
			// modal.msgError('token失效,请重新登录');
			// loadingInstance.close()
			return
		} else if (reponse.data.code == 302) {
			// modal.msgError('服务器错误,请联系管理员');
		}
		return reponse
	},
	(error) => {
		console.log(error)
		// if (error.response.status == '500') {
		// modal.msgError('服务器错误,请联系管理员');

		// loadingInstance.close()
		// }
		// 		// if (error.response) {
		// 		// 	// console.log(error.response.request.responseURL.indexOf('/loginin'))
		// 		// 	if (error.response.request.responseURL.indexOf('/loginin') > -1) {
		// 		// 		// console.log('token失效，请重新登录')
		// 		// 		window.localStorage.clear()
		// 		// 		window.location.href = '/'
		// 		// 		return error.response
		// 		// 		// return Promise.reject('token失效，请重新登录')
		// 		// 	}
		// 		// 	switch (error.response.status) {
		// 		// 		case 401:
		// 		// 			return Promise.reject('token失效，请重新登录')
		// 		// 		case 404:
		// 		// 			return Promise.reject('网络错误，请检查网络连接或联系维护人员')
		// 		// 		case 504:
		// 		// 			return Promise.reject('连接服务器失败')
		// 		// 		default:
		// 		// 			return Promise.reject('')
		// 		// 	}
		// 		// }
	}
)

axios.defaults.withCredentials = true
document.cookie = localStorage.getItem('token')
// get
function getAction(url, params, isBase = baseUrl) {
	return axiosInstance({
		url: isBase + url,
		method: 'get',
		headers: {
			'Content-Type': 'application/json',
			satoken: localStorage.getItem('token'),
		},
		params: params,
	})
}
// post
function postAction(url, data, isBase = baseUrl) {
	return axiosInstance({
		url: isBase + url,
		headers: {
			'Content-Type': 'application/json',
			satoken: localStorage.getItem('token'),
		},
		method: 'post',
		data: data,
	})
}
// postParams
function postParamsAction(url, data, isBase = baseUrl) {
	return axiosInstance({
		url: isBase + url,
		headers: {
			'Content-Type': 'application/json',
			satoken: localStorage.getItem('token'),
		},
		method: 'post',
		params: data,
	})
}
//put
function putAction(url, parameter, isBase = baseUrl) {
	return axiosInstance({
		url: isBase + url,
		method: 'put',
		headers: {
			'Content-Type': 'application/json',
			satoken: localStorage.getItem('token'),
		},
		data: parameter,
	})
}
//deleteAction
function deleteAction(url, parameter, isBase = baseUrl) {
	console.log('=>>>>>>>>>>>>>>>>>>>', parameter)
	return axiosInstance({
		// url: isBase + url + '/' + `${parameter}`,
		url: isBase + url,
		method: 'delete',
		headers: {
			'Content-Type': 'application/json',
			satoken: localStorage.getItem('token'),
		},
		data: parameter,
	})
}
function deleteActionNew(url, parameter, isBase = baseUrl) {
	console.log('=>>>>>>>>>>>>>>>>>>>', parameter)
	return axiosInstance({
		url: isBase + url + '/' + `${parameter}`,
		method: 'delete',
		headers: {
			'Content-Type': 'application/json',
			satoken: localStorage.getItem('token'),
		},
	})
}
// function deleteAction(url, parameter, isBase = baseUrl) {
// 	console.log('=>>>>>>>>>>>>>>>>>>>', parameter)
// 	return axiosInstance({
// 		url: isBase + url,
// 		method: 'delete',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			satoken: localStorage.getItem('token'),
// 		},
// 		data: parameter,
// 	})
// }

function getOrgids() {
	let userInfo = JSON.parse(window.localStorage.getItem('wlwUserInfo')) || {}
	return axiosInstance({
		url: baseUrl + api.findChildOrgByParentOrgId,
		method: 'get',
		params: {
			parentOrgId: userInfo.orgId,
			type: '2',
		},
	})
}

function jqAjax(url, type, requestContent, successCallback, isAsync = true, isJson = true, failCallback) {
	if (type == null) {
		type = 'POST'
	}
	function invocationFailure(error) {
		if (failCallback && typeof failCallback == 'function') {
			failCallback()
		}
	}
	$.ajax({
		type: type,
		url: baseUrl + url,
		data: requestContent,
		async: isAsync,
		success: successCallback,
		processData: isJson, // 使用formData传参很重要的配置
		contentType: isJson ? 'application/json; charset=utf-8' : 'application/x-www-form-urlencoded; charset=utf-8',
		dataType: 'json',
		error: function (jqXHR) {
			invocationFailure(jqXHR)
		},
	})
}

export { postAction, postParamsAction, getAction, putAction, deleteAction, deleteActionNew, getOrgids, jqAjax }
