// 接口统一管理页面
const api = {
	// 登录 首页
	pwdLogin: '/v1/sso/doLogin',
	// 首页列表
	getAppListByUser: '/web/sys-app/getAppListByUser',
	// getTicket:'/no-login/get-ticket'
	// 退出登录
	signout: '/sso/signout',
	// 修改密码
	// updatePwd:'/web/sys-user/updatePwd',
	updatePwd: '/updatePwd',
	// 获取验证码
	getCode: '/no-login/get-sms-code',
	// 验证码登录
	codeLogin: '/sso/codeLogin',
	forgetpwd: "/no-login/forget-pwd",
	// 检查是否登录
	isLogin: '/sso/login',
	// 用户中心判断是否登陆
	ssoisLogin: "/sso/isLogin"
}
export default api
